import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { HttpParamEncoder } from 'src/app/utility/http-param-encoder';
import { IResponse } from 'src/app/models/Response.interface';
import { AuthService } from 'src/app/services/auth.service';
import { UtilityService } from '../utility/utility.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  // Http methods
  public GET = 'GET';
  public POST = 'POST';
  public PUT = 'PUT';
  public PATCH = 'PATCH';
  public DELETE = 'DELETE';

  // URLs
  public authURL = '/api/Account/Login';
  public authMultiFactorCheckURL = '/api/Account/MultiFactorCheck';
  public authMultiFactorUpdateURL = '/api/Account/UpdateMFAOn';
  public authUpdateProfileURL = '/api/Account/UpdateProfileDetails';
  public adminSendAuthenticatorKeyURL = '/api/ManageAccount/Admin/SendAuthenticatorKey';
  public customerLoginURL = '/api/Account/CustomerLogin';
  public authloginWithGoogleURL = '/api/Account/LoginWithGoogle';
  public authloginWithMicroSoft = '/api/Account/LoginWithOutlook';
  public registerURL = '/api/Account/Register';
  public registerOrganizationURL = '/api/Account/RegisterOrganization';
  public getOrganizationURL = '/api/Account/GetOrganization';
  public checkOrganizationAvailabilityURL = '/api/Account/CheckOrganizationAvailability';
  public getOrganizationActivatedPlanURL = '/api/ManageAccount/Admin/GetOrganizationActivatedPlan';
  public getUsersActivityPerformanceURL = '/api/ManageAccount/Admin/Dashboard/GetUsersActivityPerformance';
  public getGetTaskBillingTimeURL = '/api/ManageAccount/Admin/Dashboard/GetTaskBillingTime';
  public getOpenClosedTasksURL = '/api/ManageAccount/Admin/Dashboard/GetOpenClosedTasks';
  public getMattersBOTStatsURL = '/api/ManageAccount/Admin/Dashboard/GetMattersBOTStats';
  public getMattersRetainerStatsURL = '/api/ManageAccount/Admin/Dashboard/GetMattersRetainerStats';
  public getMattersBudgetActualURL = '/api/ManageAccount/Admin/Dashboard/GetMatterBudgetActual';
  public getDashboardWidgetCounterURL = '/api/ManageAccount/Admin/Dashboard/GetDashboardWidgetCounter';
  public appointmentsURL = '/api/Appointment';
  public appointmentsFilterURL = '/api/Appointment/Filter';
  public appointmentInvitationStatusURL = '/api/Appointment/InvitationStatus';
  public appointmentTypeURL = '/api/Appointment/Type';
  public appointmentHistoryURL = '/api/Audit/Appointment';
  public pausedTimeSlipsURL = 'api/TimeSlip/PausedTimeSlips';
  public startTimerURL = '/api/TimeSlip/StartTimer';
  public tasksURL = '/api/ToDo';
  public tasksFilterURL = '/api/ToDo/Filter';
  public tasksGetWithFilterTimeSlipAssigneesURL = '/api/ToDo/GetWithFilterTimeSlipAssignees';
  public tasksStatusURL = '/api/ToDo/status';
  public tasksNotificationURL = '/api/ToDo/Notification';
  public tasksUpdateStatusURL = '/api/ToDo/ChangeToDoStatus';
  public taskTypesURL = '/api/ToDo/type';
  public taskHistoryURL = '/api/Audit/ToDo';
  public taskOrgDefinedStatusHistoryURL = '/api/ToDo/AllToDoOrgDefinedHistory';
  public taskOrgDefinedStatusURL = '/api/ToDo/OrgDefinedStatus';
  public taskAssigneeFilteredURL = '/api/ToDo/AssigneeListFiltered';
  public contactTicketArchiveURL = '/api/ContactTicket/Archive';
  public contactTicketURL = '/api/ContactTicket';

  public taxCodesURL = '/api/Contact/TaxCode';
  public taxCodeConfigURL = '/api/ManageAccount/Admin/UpdateTaxCodeConfig';

  public twilioNumberAdd = "/api/ManageAccount/Admin/AttachTwilioToOrganization";
  public gmailSettingsUpdateURL = "/api/ManageAccount/Admin/UpdateOrganizationGmailSettings";
  public inventoryAPIUpdateURL = "/api/ManageAccount/Admin/UpdateOrganizationInventoryAPI";

  public conversationFilterURL = '/api/Conversation/Filter';
  public conversationURL = '/api/Conversation';
  public conversationMessageURL = '/api/Conversation/Message';
  public conversationManageParticipantURL = '/api/Conversation/ManageParticipant';
  public conversationMarkReadURL = '/api/Conversation/UpdateConversationMarkViewed';

  public qbTimeSlipItemsURL = '/api/TimeSlip/QBItems'
  public timeSlipQBItemURL = '/api/TimeSlip/QBItem';
  public qbItemRecordURL = '/api/TimeSlip/QBItemRecord';

  public qbToDoItemsURL = '/api/ToDo/QBItems'
  public qbToDoAllItemsURL = '/api/ToDo/AllQBItems'
  public toDoQBItemURL = '/api/ToDo/QBItem';

  public systemNotificationFilterURL = 'api/SystemNotification/Filter';
  public systemNotificationURL = 'api/SystemNotification';
  public systemNotificationReadURL = 'api/SystemNotification/Read';

  public companyURL = '/api/Company';
  public companyContactUploadURL = '/api/Company/ImportExcel';
  public companyContactDownloadSample = '/api/Company/DownloadSampleImportExcel';
  public companyHistoryURL = '/api/Audit/Company';
  public companyDocumentsURL = '/api/Company/Documents';

  public contactsURL = '/api/contact';
  public contactsOnlyURL = '/api/contact/GetOnlyContacts';
  public contactCompaniesURL = '/api/contact/Companies';
  public contactFilterURL = '/api/Contact/Filter';
  public contactCategoryURL = '/api/Contact/Category';
  public contactAddToExistingCompanyURL = '/api/Contact/AddContactToExistingCompany';
  public contactHistoryURL = '/api/Audit/Contact';
  public allowPortalLoginURL = '/api/Contact/AllowPortalLogin';
  public customerLoginSendCodeURL = '/api/Account/CustomerLoginSendCode';

  public contactContactURL = '/api/Contact/Company';
  public contactMoveContactURL = '/api/Contact/MoveCompany';
  public contactDetachURL = '/api/Contact/DetachContact';

  public mattersURL = '/api/matter';
  public mattersGetWithFilterTimeSlipAssigneesURL = '/api/Matter/GetWithFilterTimeSlipAssignees';
  public mattersFilterURL = '/api/Matter/Filter';
  public matterContactURL = '/api/Matter/Contact';
  public matterContactsURL = '/api/Matter/Contacts';
  public matterOrgDefinedStatusHistoryURL = '/api/Matter/AllMatterOrgDefinedHistory';
  public matterStaffURL = '/api/Matter/Staff';
  public matterStaffsURL = '/api/Matter/Staffs';
  public matterLawTypesURL = '/api/LawType';
  public matterStaffRolesURL = '/api/Matter/StaffRoles';
  public matterOrgDefinedStatusURL = '/api/Matter/OrgDefinedStatus';
  public matterHistoryURL = '/api/Audit/Matter';
  public matterDocumentsURL = '/api/Matter/Documents';
  public matterTasksURL = '/api/Matter/ToDos';
  public matterStatusToCompletedURL = '/api/Matter/StatusToCompleted';
  public matterSnoozeURL = '/api/Matter/Snooze';
  public matterActivitiesURL = '/api/Matter/TimeSlips';
  public matterAppointmentsURL = '/api/Matter/Appointments';
  public matterGetAllExtraBlockOfTimeURL = '/api/Matter/GetAllExtraBlockOfTime';
  public matterAddExtraBlockOfTimeURL = '/api/Matter/AddExtraBlockOfTime';
  public matterGetAllExtraBlockOfRetainerURL = '/api/Matter/GetAllExtraBlockOfRetainer';
  public matterAddExtraBlockOfRetainerURL = '/api/Matter/AddExtraBlockOfRetainer';
  public matterNotificationURL = '/api/Matter/Notification';
  public billingMethodURL = '/api/Matter/BillingMethods';
  public matterInvoiceURL = '/api/Invoice/Filter';
  public getInvoiceURL = '/api/Invoice';

  public activityURL = '/api/TimeSlip';
  public assigneeCheckInOutURL = '/api/TimeSlip/AssigneeCheckInOut';
  public approvalStatusURL = '/api/TimeSlip/ApprovalStatus';
  public batchApprovalStatusURL = '/api/TimeSlip/BatchApproval';

  public activityHistoryURL = '/api/Audit/TimeSlip';
  public activityFilterURL = '/api/TimeSlip/Filter';
  public assigneeListURL = '/api/TimeSlip/AssigneeList';
  public allFilterAssigneeListURL = '/api/TimeSlip/AllFilterAssigneeList';
  public activityAssigneeURL = '/api/TimeSlip/Assignee';
  public activityReplaceAssigneeURL = '/api/TimeSlip/ReplaceAssignee';
  public activityAssigneeFilteredURL = '/api/TimeSlip/AssigneeListFiltered';
  public taskAssigneeListURL = '/api/ToDo/AssigneeList';
  public matterAssigneeListURL = '/api/Matter/AllStaffList';
  public matterAssigneeFilterListURL = '/api/Matter/FilterStaffList';
  public updateActivityTimeOnlyURL = '/api/TimeSlip/PutTimeOnly';


  public documentsURL = '/api/document';
  public documentsFilterURL = '/api/document/Filter';
  public documentTypeURL = '/api/document/Type';
  public documentSubTypeURL = '/api/document/subType';
  public documentClassURL = '/api/document/class';
  public documentDeletePermissionURL = '/api/document/UserPermission';
  public documentReviewersURL = '/api/document/Reviewers';
  public documentReviewerURL = '/api/document/Reviewer';
  public documentPermissionURL = '/api/document/Permission';
  public documentAddPermissionURL = '/api/Document/UserPermissions';

  public userListURL = '/api/ManageAccount/Users';

  public documentsDownloadURL = '/api/document/Download';

  public usersURL = '/api/ManageAccount/Users';
  public employeesURL = '/api/ManageAccount/Employees/Filter'
  public employeeUserMapping = '/api/ManageAccount/Admin/UpdateUserEmployeeRef'
  public paymentStatusForUsersURL = '/api/ManageAccount/Users/PaymentStatusForUsers';
  public adminUsersURL = '/api/ManageAccount/Admin/GetUsers';
  public adminCreateUserURL = '/api/ManageAccount/Admin/CreateUser';
  public adminUpdateUserURL = '/api/ManageAccount/Admin/UpdateUser';
  public adminDeleteUserURL = '/api/ManageAccount/Admin/DeleteUsers';
  public changeUserStatusURL = '/api/ManageAccount/Admin/ChangeUserStatus';
  public changeUserLicenseStatusURL = '/api/ManageAccount/Admin/ChangeUserLicenseStatus';
  public getLicencesURL = '/api/ManageAccount/Admin/GetLicences';
  public addLicenceURL = '/api/ManageAccount/Admin/AddLicence';
  public updateLicenceURL = '/api/ManageAccount/Admin/UpdateLicenceByOrgAdmin';
  public toDoTypesURL = '/api/ManageAccount/Role/ToDoTypes';
  public roleQBItemsURL = '/api/ManageAccount/Role/QBItems';
  public multipleToDoTypesURL = '/api/ManageAccount/Admin/Role/MultipleToDoTypes';
  public getOrganizationsURL = '/api/ManageAccount/Admin/GetOrganizations';
  public getMyOrganizationURL = '/api/ManageAccount/Admin/GetMyOrganization';
  public deleteAccountRequestURL = '/api/ManageAccount/Admin/DeleteAccountRequest';
  public deleteAccountURL = '/api/ManageAccount/Admin/DeleteAccount';
  public addTimeSheetUserIdURL = '/api/ManageAccount/Admin/AddTimeSheetUserId';
  // public getOrganizationsURL = '/api​/ManageAccount​/Admin​/GetOrganizations';



  public rolesURL = '/api/ManageAccount/Admin/Permission';
  public unArchiveRoleURL = '/api/ManageAccount/Admin/UnArchiveRole';
  public unArchiveUserURL = '/api/ManageAccount/Admin/UnArchiveUser';
  public UnArchiveCompanyURL = '/api/Company/UnArchiveCompany';

  public rolesGetURL = '/api/ManageAccount/Roles';
  public rolesCreateURL = '/api/ManageAccount/Admin/CreateRole';
  public rolesUpdateURL = '/api/ManageAccount/Admin/UpdateRole';
  public rolesDeleteURL = '/api/ManageAccount/Admin/DeleteRole';
  public rolePermissionUpdateURL = '/api/ManageAccount/Admin/AssignPermission';
  public activateUserPaymentURL = '/api/ManageAccount/Admin/ActivateUserPayment';
  public getSubscriptionSummaryURL = '/api/ManageAccount/Admin/GetSubscriptionSummary';
  public makePaymnetMethodAsDefaultURL = '/api/ManageAccount/Admin/MakePaymnetMethodAsDefault';
  public createPaymentMethodURL = '/api/ManageAccount/Admin/CreatePaymentMethod';
  public peermissionURL = '/api/ManageAccount/Permission';

  public searchURL = '/api/Search';
  public searchUserURL = '/api/ToDo/Users';
  public todoDocumentURL = '/api/ToDo/Documents';
  public activityDocumentURL = '/api/TimeSlip/Documents'
  public todoAssigneeURL = '/api/ToDo/Assignee';
  public todoAssigneesURL = '/api/ToDo/Assignees';
  public billingPricesURL = '/api/Billing/pricing';
  public billingPriceURL = '/api/Billing/pricing';
  public billingCheckoutSessionURL = '/api/Billing/create-checkout-session';
  public orgRegistrationBillingCheckoutSessionURL = '/api/Billing/create-checkout-session-public';
  public quickBookPreviewInvoiceURL = '/api/QuickBook/preview-invoice';
  public quickBookVoidInvoiceURL = '/api/QuickBook/void-invoice';
  public quickBookCreateInvoiceURL = '/api/QuickBook/create-invoice';
  public quickBookCreateAllInvoiceURL = '/api/QuickBook/create-allinvoices';
  public quickBookServiceURL = '/api/QuickBook/QB-services';
  public quickBookTaxCodeURL = '/api/QuickBook/QB-TaxCodeRef';
  public quickBookInventoryItemURL = '/api/QuickBook/QB-Inventory-Item';
  public quickBookGetEmployeesURL = '/api/QuickBook/QB-Employees';
  public quickBookNonInventoryItemURL = '/api/QuickBook/QB-NonInventory-Item';
  public quickBookCompanyURL = '/api/Contact/QB-SyncComapnies';
  public connectQuickBookServiceURL = '/api/QuickBook/Connect';
  public validateQBRedirectionURL = '/api/QuickBook/ValidateQBRedirection';

  public getWeekDayConfigURL = '/api/ManageAccount/Admin/GetWeekDayConfig';
  public UpdateWeekDayConfigURL = '/api/ManageAccount/Admin/UpdateWeekDayConfig';
  public UpdateActivityBehaviourConfigURL = '/api/ManageAccount/Admin/UpdateActivityBehaviourConfig';
  public UpdateAutoCloseOnCompletedBehaviourConfigURL = '/api/ManageAccount/Admin/UpdateTaskAutoCloseOnCompletedBehaviourConfig';
  public UpdateOrganizationConfigURL = '/api/ManageAccount/Admin/UpdateOrganization';
  public UpdateInvoiceEmailRequiredConfigURL = '/api/ManageAccount/Admin/UpdateInvoiceEmailRequiredConfig';

  public StartCronJobURL = '/api/ManageAccount/Admin/StartCronJob';
  public CancelCronJobURL = '/api/ManageAccount/Admin/CancelCronJob';

  public communicationsFilterURL = '/api/Communication/Filter';
  public communicationsTagsURL = '/api/Communication/Tags';
  public communicationsContactWiseURL = '/api/Communication/ContactWise';
  public communicationsURL = '/api/Communication';
  public communicationStatusURL = '/api/Communication/Status';
  public communicationTypesURL = '/api/Communication/Types';
  public communicationBulkMessageURL = '/api/Communication/SendBulkMessage';
  public communicationRestoreURL = '/api/Communication/Restore';
  public communicationBulkRestoreToScheduleFromNoFollowUpURL = '/api/Communication/BulkRestoreToScheduleFromNoFollowUp';
  public communicationBulkMoveToNoFollowUpURL = '/api/Communication/MoveToNoFollowUp';

  public UIElementURL = '/api/UIelement';
  public UIElementLabelURL = '/api/UIelement/Label';

  public agilityNotificationURL = '/api/ManageAccount/Admin/GetMyAgilityNotifications';

  public userNotificationURL = '/api/ManageAccount/Admin/GetUserNotifications';

  public deletUserNotificationURL = '/api/ManageAccount/Admin/DeleteUserNotification';

  public messageNotificationURL = '/api/ManageAccount/Admin/GetMessageNotifications';

  public calculateCostURL = '/api/ToDoBilling/calculate-cost';
  public calculateBillingURL = '/api/ToDoBilling/calculate-billing';
  public updateToDoTimeURL = '/api/ToDo/UpdateToDoTime';
  public updateToDoRateURL = '/api/ToDo/UpdateToDoRate';


  public changePasswordURL = '/api/Account/ChangePassword';
  public forgotPasswordURL = '/api/Account/ForgotPassword';
  public uploadProfilePicURL = '/api/Account/UploadProfilePic';

  //CheckInOut

  public checkInOutFilterURL = '/api/CheckInOut/Filter';
  public ongoingCheckInOutURL = '/api/CheckInOut/OngoingCheckInOut';
  public checkInOutURL = '/api/CheckInOut';
  public checkInOutApprovalStatusURL = '/api/CheckInOut/ApprovalStatus';
  public updateLoggedMinutesURL = ' /api/CheckInOut/UpdateLoggedMinutes';
  public updateOnlyCheckedTimeURL = ' /api/CheckInOut/UpdateOnlyCheckedTime';
  public createCheckInOutItemsURL = '/api/CheckInOut/CheckInOutItems';
  public createCheckInOutItemVerificationURL = '/api/CheckInOut/CheckInOutItems/Verification';
  public checkInOutIsRequiredURL = '/api/CheckInOut/CheckInOutIsRequired';
  public createCheckInOutItemsCheckTimeOverlapURL = '/api/CheckInOut/CheckTimeOverlap';
  public checkInOutItemsRefreshQBTimeSheetURL = '/api/CheckInOut/RefreshQBTimeSheet';




  constructor(private http: HttpClient, private auth: AuthService,
    private notification: NzNotificationService,

  ) { }

  public async request<T = any>(
    method: string,
    URL: string,
    body: any = null,
    params: any = {},
    isLoginRequest: boolean = false
  ): Promise<IResponse<T>> {
    const httpParams = new HttpParams({
      encoder: new HttpParamEncoder(),
      fromObject: params,
    });

    if (method === this.GET) {
      return this.http
        .get(URL, { params: httpParams })
        .toPromise()
        .then((response: IResponse) => response)
        .catch((error: HttpErrorResponse) => {
          console.log(error.error);
          if (error.status === 401 && !isLoginRequest) {
            this.notification.create("error", "Session timeout", "", { nzKey: 'key', nzPlacement: 'bottomRight' });

            this.auth.logout();
            window.location.reload();
            return null;
          }
          throw error.error;
        });
    } else if (method === this.POST) {
      return this.http
        .post(URL, body, { params: httpParams })
        .toPromise()
        .then((response: IResponse) => response)
        .catch((error: HttpErrorResponse): IResponse => {
          console.log(error.error);

          if (error.status === 401 && !isLoginRequest) {
            this.notification.blank("error", "Session timeout", { nzKey: 'key', nzPlacement: 'bottomRight' });

            this.auth.logout();
            window.location.reload();

            return null;
          }
          throw error.error;
        });
    } else if (method === this.PUT) {
      return this.http
        .put(URL, body, { params: httpParams })
        .toPromise()
        .then((response: IResponse) => response)
        .catch((error: HttpErrorResponse): IResponse => {
          console.log(error.error);
          if (error.status === 401 && !isLoginRequest) {
            this.auth.logout();
            window.location.reload();

            return null;
          }
          throw error.error;
        });
    } else if (method === this.DELETE) {
      if (body != null) {
        return this.http
          .request('DELETE', URL, {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            body: body
          })
          .toPromise()
          .then((response: IResponse): IResponse => response)
          .catch((error: HttpErrorResponse): IResponse => {
            console.log(error.error);
            if (error.status === 401 && !isLoginRequest) {
              this.notification.create("error", "Session timeout", "", { nzKey: 'key', nzPlacement: 'bottomRight' });

              this.auth.logout();
              window.location.reload();

              return null;
            }
            throw error.error;
          });
      } else {
        return this.http
          .delete(URL, { params: httpParams })
          .toPromise()
          .then((response: IResponse): IResponse => response)
          .catch((error: HttpErrorResponse): IResponse => {
            console.log(error.error);
            if (error.status === 401 && !isLoginRequest) {
              this.notification.create("error", "Session timeout", "", { nzKey: 'key', nzPlacement: 'bottomRight' });

              this.auth.logout();
              window.location.reload();

              return null;
            }
            throw error.error;
          });
      }

    } else if (method === this.PATCH) {
      return this.http
        .patch(URL, body, { params: httpParams })
        .toPromise()
        .then((response: IResponse): IResponse => response)
        .catch((error: HttpErrorResponse): IResponse => {
          console.log(error.error);
          if (error.status === 401 && !isLoginRequest) {
            this.notification.create("error", "Session timeout", "", { nzKey: 'key', nzPlacement: 'bottomRight' });

            this.auth.logout();
            window.location.reload();

            return null;
          }
          throw error.error;
        });
    } else {
      throw Error('Unknow type of request!');
    }
  }
}
